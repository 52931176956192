





































































































































































































































































import {Component, Prop, Watch} from 'vue-property-decorator'
import TokenCellRender from '@/components/render/TokenCellRender.vue'
import TokensSwapButton from '@/components/tokensprice/TokensSwapButton.vue'
import PercentageProgressRender from '@/components/render/PercentageProgressRender.vue'
import BounceLoader from 'vue-spinner/src/BounceLoader.vue'
import LastPriceUpdated from '@/components/LastPriceUpdated.vue'
import PoweredBy, {PoweredByItem} from '@/components/PoweredBy.vue'
import {MyWalletTokensTableItem} from '@/model/resource/MyWalletTokensTableItem'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {MyWalletCacheAssist} from '@/helpers/MyWalletCacheAssist'
import {$} from '@/facade'
import MyWalletProceedButton from '@/components/myWallet/MyWalletProceedButton.vue'
import MyWalletDisclaimerMobile from '@/components/myWallet/MyWalletDisclaimerMobile.vue'

@Component({
  computed: {
    $() {
      return $
    },
  },
  components: {
    MyWalletDisclaimerMobile,
    MyWalletProceedButton,
    PoweredBy,
    LastPriceUpdated,
    PercentageProgressRender,
    TokensSwapButton,
    TokenCellRender,
    BounceLoader,
  },
})
export default class MyWalletTokensTable extends MixinScreenSize {
  @Prop({type: MyWalletCacheAssist, required: true})
  cacheAssist!: MyWalletCacheAssist

  openMobileDisclaimer = false

  headers = ['TOKEN', 'BALANCE', 'VALUE', 'PRICE', '24H %', '7D %', '']

  poweredByList = [
    {
      url: 'https://flamingo.finance/',
      alt: 'flamingo icon',
      filename: 'flamingo-icon.svg',
    } as PoweredByItem,
    {
      url: 'https://www.forthewin.network/#/',
      alt: 'ftwicon',
      filename: 'for-the-win-logo.svg',
    } as PoweredByItem,
    {
      url: 'https://neofura-1.gitbook.io/api-docs/',
      alt: 'magnet icon',
      filename: 'magnet-icon.svg',
    } as PoweredByItem,
    {
      url: 'https://carrot-fi.xyz/',
      alt: 'carrot swap icon',
      filename: 'carrot-swap.svg',
    } as PoweredByItem,
  ]

  get lastTimePriceUpdated() {
    return this.cacheAssist.currentWallet.tokenLastTimeUpdated || ''
  }

  get displayedTokens() {
    return this.cacheAssist.currentWallet.tokenAssets?.slice(0, 5)
  }

  set displayedTokens(value: MyWalletTokensTableItem[]) {
    this.cacheAssist.currentWallet.tokenAssets = value
  }

  get moreItemsAvailableToDisplay() {
    return this.cacheAssist.currentWallet.tokenAssets?.slice(5)
  }

  get popoverText() {
    if (this.cacheAssist.isWatchWalletInUse) {
      return this.$translate(
        'components.MyWalletTokensTable.watchWalletDisclaimer'
      )
    }

    return this.$translate(
      'components.MyWalletTokensTable.unavailableDisclaimer'
    )
  }

  isSwapButtonDisabled(item: MyWalletTokensTableItem) {
    if (this.cacheAssist.isWatchWalletInUse) {
      return true
    }

    return !item.isSwappable
  }

  setOpenMobileDisclaimer(val: boolean) {
    this.openMobileDisclaimer = val
  }

  loadMore() {
    const moreItems = this.moreItemsAvailableToDisplay.splice(0, 5)

    this.displayedTokens.push(...moreItems)
  }

  async onClickSwap(token: MyWalletTokensTableItem) {
    await this.$walletAdapter.executeWithConnectedN3Wallet(() => {
      this.$modal.open('MyWalletSwapModal', token)
    })
  }
}
