






















































import {Mixins, Component, Watch} from 'vue-property-decorator'
import NavbarMenu from '@/components/NavbarMenu.vue'
import AppFooter from '@/components/AppFooter.vue'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {$} from '@/facade'
import MyWalletSection from '@/components/myWallet/MyWalletSection.vue'
import MyWalletTokensNftsSection from '@/components/myWallet/MyWalletTokensNftsSection.vue'
import MyWalletGasCalculatorSection from '@/components/myWallet/MyWalletGasCalculatorSection.vue'
import MyWalletGetGasSection from '@/components/myWallet/MyWalletGetGasSection.vue'
import {MyWalletCacheAssist} from '@/helpers/MyWalletCacheAssist'
import MyWalletMyNfts from '@/components/myWallet/MyWalletMyNfts.vue'
import MyWalletConnectAddress from '@/components/myWallet/MyWalletConnectAddress.vue'
import MyWalletSwapModal from '@/components/myWallet/MyWalletSwap/MyWalletSwapModal.vue'
import MyWalletWatchOnlyDisclaimer from '@/components/myWallet/MyWalletWatchOnlyDisclaimer.vue'

@Component({
  computed: {
    $() {
      return $
    },
  },
  components: {
    MyWalletWatchOnlyDisclaimer,
    MyWalletSwapModal,
    MyWalletConnectAddress,
    MyWalletMyNfts,
    MyWalletGasCalculatorSection,
    MyWalletGetGasSection,
    MyWalletTokensNftsSection,
    MyWalletSection,
    AppFooter,
    NavbarMenu,
  },
})
export default class MyWalletView extends Mixins(MixinScreenSize) {
  cacheAssist: MyWalletCacheAssist = new MyWalletCacheAssist()

  displayDisclaimer = false

  mounted() {
    if ($.walletAdapter.n3Address) {
      this.cacheAssist.populateWallet()
      this.displayDisclaimer = false
    }

    this.displayDisclaimer = true
  }

  @Watch('$store.state.walletAdapter.n3Address')
  onN3AddressChange(address: string | null) {
    if (address) {
      this.cacheAssist.populateWallet()
      this.displayDisclaimer = false
      return
    }

    if ($.watchWallet.address) {
      this.cacheAssist.populateWatchWallet($.watchWallet.address)
      this.displayDisclaimer = true
      return
    }
  }
}
