var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full"},[_c('div',{staticClass:"my-wallet-tokens-table"},[_c('await',{staticClass:"w-full h-full",attrs:{"name":"populateMyWalletAssets","spinner":"MoonLoader"}},[_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"horiz items-center justify-between my-4"},[_c('last-price-updated',{attrs:{"display-inline":true,"last-time-updated":_vm.lastTimePriceUpdated}}),(!_vm.isMobile && !_vm.isTablet)?_c('powered-by',{attrs:{"items":_vm.poweredByList}}):_vm._e()],1),_c('div',{staticClass:"my-wallet-tokens-table__table-container"},[_c('table',[_c('thead',[_c('tr',[_c('th'),_vm._l((_vm.headers),function(header,key){return _c('th',{key:key},[_vm._v(" "+_vm._s(header)+" ")])})],2)]),_c('tbody',_vm._l((_vm.displayedTokens),function(item,index){return _c('tr',{key:index},[_c('td',[_c('div',{staticClass:"horiz items-center w-full"},[_c('div',{staticClass:"w-12 h-12 sm:w-16 sm:h-16 rounded-full bg-white shadow-md overflow-hidden"},[_c('img',{attrs:{"alt":item.symbol,"src":item.imageUrl}})])])]),_c('td',[_c('token-cell-render',{attrs:{"blockchain-tag-name":item.blockchainVersion
                        ? item.blockchainVersion.tagName
                        : '',"owner-address":item.marketInformation
                        ? item.marketInformation.ownerAddress
                        : '',"slug":item.marketInformation
                        ? item.marketInformation.slugDapp
                        : '',"symbol":item.symbol,"title-dapp":item.marketInformation
                        ? item.marketInformation.titleDapp
                        : ''}})],1),_c('td',[_c('span',{staticClass:"my-wallet-tokens-table__span"},[_vm._v(" "+_vm._s(item.balanceFormatted)+" ")])]),_c('td',[_c('span',{staticClass:"my-wallet-tokens-table__span"},[_vm._v(" "+_vm._s(item.valueFormatted)+" ")])]),_c('td',[_c('span',{staticClass:"my-wallet-tokens-table__span"},[_vm._v(" "+_vm._s(item.priceFormatted)+" ")])]),_c('td',[_c('percentage-progress-render',{attrs:{"percentage-value":item.priceVariationLastDayFormatted,"input-class":"text-lg text-center"}})],1),_c('td',[_c('percentage-progress-render',{attrs:{"percentage-value":item.priceVariationLastWeekFormatted,"input-class":"text-lg text-center"}})],1),_c('td',[_c('my-wallet-proceed-button',{staticClass:"my-wallet-tokens-table__swap-btn",attrs:{"is-disabled":_vm.isSwapButtonDisabled(item),"popover-text":_vm.popoverText,"show-arrow":true,"title-text":_vm.$translate('components.MyWalletTokensTable.swap')},on:{"call-action":function($event){return _vm.onClickSwap(item)},"open-mobile-disclaimer":_vm.setOpenMobileDisclaimer}})],1)])}),0)]),(_vm.displayedTokens && _vm.displayedTokens.length === 0)?_c('div',{staticClass:"uppercase text-center text-black-600 text-lg py-6 font-light"},[_vm._v(" "+_vm._s(_vm.$translate('app.noDataToShow'))+" ")]):_vm._e()]),_c('my-wallet-disclaimer-mobile',{staticClass:"mt-4",attrs:{"show":_vm.openMobileDisclaimer,"text":_vm.popoverText}}),(
            _vm.moreItemsAvailableToDisplay &&
              _vm.moreItemsAvailableToDisplay.length > 0
          )?_c('div',{staticClass:"w-full horiz items-center-center mt-8"},[_c('button',{staticClass:"btn btn--contrast my-wallet-tokens-table__load-more-btn",attrs:{"type":"button"},on:{"click":_vm.loadMore}},[_vm._v(" "+_vm._s(_vm.$translate('components.MyWalletTokensTable.loadMore'))+" ")])]):_vm._e()],1)])],1),(_vm.isMobile || _vm.isTablet)?_c('powered-by',{staticClass:"mt-3",attrs:{"display-inline":true,"items":_vm.poweredByList}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }